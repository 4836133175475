































































import Vue from 'vue'
import store from '@/store'

export default Vue.extend({
  data() {
    return {
      active: false,
      dataLoading: false,
    }
  },
  computed: {
    getHtmlCode(): string {
      return store.getters['htmlTemplate/getHtmlCode']
    },
    getHtmlHeight(): number {
      return store.getters['htmlTemplate/getHeight']
    },
    getHtmlWidth(): number {
      return store.getters['htmlTemplate/getWidth']
    },
    getHeightWithPxPostfix(): string {
      return store.getters['htmlTemplate/getHeightWithPxPostfix']
    },
    getWidthWithPxPostfix(): string {
      return store.getters['htmlTemplate/getWidthWithPxPostfix']
    },
    getWidthError(): boolean {
      return false
    },
    getName(): string {
      return store.getters['htmlTemplate/getName']
    },
    getTemplateId(): string {
      return store.getters['htmlTemplate/getTemplateId']
    },
    getWidgetId(): string {
      return store.getters['htmlTemplate/getWidgetId']
    },

  },
  methods: {
    hidePopup() {
      this.active = false
    },
    showPopup() {
      this.active = true
    },
    onUpdateWidth(event: any) {
      store.dispatch('htmlTemplate/setWidth', event.target.value)
    },
    onUpdateHeight(event: any) {
      store.dispatch('htmlTemplate/setHeight', event.target.value)
    },
    onUpdateCode(event: any) {
      store.dispatch('htmlTemplate/setHtml', event.target.value)
    },
    onUpdateName(event: any) {
      store.dispatch('htmlTemplate/setName', event.target.value)
    },
    onUpdateId(event: any) {
      store.dispatch('htmlTemplate/setId', event.target.value)
    },
    onWidgetIdChange(event: any) {
      store.dispatch('htmlTemplate/setWidgetId', event.target.value)
    },
    storeHtmlTemplate() {
      store.dispatch('htmlTemplate/storeHtmlTemplate')
      this.hidePopup()
      store.dispatch('htmlTemplate/resetToInitialState')
    },
  },
})


















































































































import Vue from 'vue'
import store from '@/store'
import { ExternalLinkIcon, ImageIcon } from 'vue-feather-icons'
import { HtmlTemplate } from '@/store/htmlTemplate/htmlTemplate'
import { PopupSettings } from '@/store/htmlTemplate/htmlTemplateEditor'

const MAILING_API_HOST = 'quarticon.com'
const TEST_EMAIL = 'test@quarticon.com'
const MAILING_SUBDOMAIN = 'im-pl'
const MAILING_SUBDOMAIN_FIRST_PARTY = 'im2'

export default Vue.extend({
  name: 'SnippetPopup',
  components: { ImageIcon, ExternalLinkIcon },
  data() {
    return {
      previewEmail: TEST_EMAIL,
      mailingApiHost: MAILING_API_HOST,
      useFirstParty: false,
    }
  },
  computed: {
    snippetPopup(): PopupSettings {
      return store.getters['htmlTemplateEditor/getSnippetPopupSettings']
    },
    getHeightWithPxPostfix() {
      return store.getters['htmlTemplateEditor/getHeightWithPxPostfix']
    },
    getWidthWithPxPostfix() {
      return store.getters['htmlTemplateEditor/getWidthWithPxPostfix']
    },
    getProductPreviewSnippet(): string {
      return store.getters['htmlTemplateEditor/getProductPreviewSnippet']
    },
    getNumberOfProductsFromStore(): number {
      return store.getters['htmlTemplateEditor/getNumberOfProductsFromStore']
    },
    getCustomerSymbol(): string {
      const userData = store.getters['user/userData']
      return userData.customerSymbol
    },
    getWidgetId(): number {
      return store.getters['htmlTemplateEditor/getWidgetId']
    },
    getApiHost(): string {
      const subdomain = this.useFirstParty ? MAILING_SUBDOMAIN_FIRST_PARTY : MAILING_SUBDOMAIN
      return `https://${subdomain}.${this.mailingApiHost}/v2`
    },

    getApiLink(): string {
      return `${this.getApiHost}/url/${this.getCustomerSymbol}/%TODAY%/%EMAIL%/${this.getEditedTemplate.templateId}/${this.getWidgetId}/`
    },
    getImgLink(): string {
      return `${this.getApiHost}/img/${this.getCustomerSymbol}/%TODAY%/%EMAIL%/${this.getEditedTemplate.templateId}/${this.getWidgetId}/`
    },

    getPreparedImgLink(): string {
      return this.replaceEmailMacro(this.replaceDateMacro(this.getImgLink))
    },
    getPreparedApiLink(): string {
      return this.replaceEmailMacro(this.replaceDateMacro(this.getApiLink))
    },

    generateSnippedArray(): Array<string> {
      const numberOfProducts = Number(this.getNumberOfProductsFromStore)
      const output = []
      for (let i = 0; i < numberOfProducts; i++) {
        const productId = i
        output.push(`<a href="${this.getApiLink}${productId}">\n`
          + `    <img alt="product ${productId}" src="${this.getImgLink}${productId}">\n`
          + '</a>')
      }

      return output
    },
    getCustomersTemplates(): Array<HtmlTemplate> {
      return store.getters['htmlTemplateEditor/getCustomersTemplates']
    },
    getEditedTemplate(): HtmlTemplate {
      return store.getters['htmlTemplateEditor/getEditedTemplate']
    },
  },
  methods: {
    replaceEmailMacro(link: string): string {
      return link.replace(/%EMAIL%/, this.previewEmail)
    },
    replaceDateMacro(link: string): string {
      return link.replace(/%TODAY%/, String(this.getWidgetId))
    },
    onNumberOfProductsChange(event: any) {
      store.dispatch('htmlTemplateEditor/setNumberOfProducts', event)
    },
    onWidgetIdChange(event: any) {
      store.dispatch('htmlTemplateEditor/setWidgetId', event.target.value)
    },
    showSnippetPopup(rowIndex: number): void {
      const popupSettings = {
        active: true,
        rowIndex,
      }
      store.dispatch('htmlTemplateEditor/storeSnippetPopupSettings', popupSettings)
      this.storeEditedTemplate(rowIndex)
    },
    storeEditedTemplate(rowIndex: number): void {
      store.dispatch('htmlTemplateEditor/setEditedTemplate', rowIndex)
    },
    updateDataInRow(rowIndex: number): void {
      store.dispatch('htmlTemplateEditor/updateDataInRow', rowIndex)
    },
  },
})

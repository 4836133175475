


























































import Vue from 'vue'
import store from '@/store'
import { EditIcon, ZoomInIcon, CodeIcon } from 'vue-feather-icons'
import SnippetPopup from './SnippetPopup.vue'
import EditTemplateSettingsPopup from './EditTemplateSettingsPopup.vue'
import EditTemplateCodePopup from './EditTemplateCodePopup.vue'

export default Vue.extend({
  components: {
    EditIcon,
    ZoomInIcon,
    CodeIcon,
    SnippetPopup,
    EditTemplateSettingsPopup,
    EditTemplateCodePopup,
  },
  props: [
    'columns', 'title',
  ],
  data() {
    return {
      dataLoading: true,
    }
  },
  computed: {
    getCustomersTemplates() {
      return store.getters['htmlTemplateEditor/getCustomersTemplates']
    },
  },
  mounted() {
    this.dataLoading = true
    store.dispatch('htmlTemplateEditor/getAllTemplates')
    this.dataLoading = false
  },
  methods: {
    showEditSettingsPopup(rowIndex: number): void {
      const popupSettings = {
        active: true,
        rowIndex: rowIndex
      }
      store.dispatch('htmlTemplateEditor/storeEditSettingsPopup', popupSettings)
      this.storeEditedTemplate(rowIndex)
    },
    showEditTemplatesCodePopup(rowIndex: number): void {
      const popupSettings = {
        active: true,
        rowIndex: rowIndex
      }
      store.dispatch('htmlTemplateEditor/storeTemplatesCodePopup', popupSettings)
      this.storeEditedTemplate(rowIndex)
    },

    showSnippetPopup(rowIndex: number): void {
      const popupSettings = {
        active: true,
        rowIndex: rowIndex
      }
      store.dispatch('htmlTemplateEditor/storeSnippetPopupSettings', popupSettings)
      this.storeEditedTemplate(rowIndex)
    },
    storeEditedTemplate(rowIndex: number): void {
      store.dispatch('htmlTemplateEditor/setEditedTemplate', rowIndex)
    },
  },
})

























































import store from "@/store";
import {CodeIcon, EditIcon, ExternalLinkIcon, ImageIcon, ZoomInIcon} from "vue-feather-icons";
import {HtmlTemplate} from "@/store/htmlTemplate/htmlTemplate";
import {PopupSettings} from "@/store/htmlTemplate/htmlTemplateEditor";

export default {
  name: "EditTemplateSettingsPopup",
  components: {
    EditIcon, ZoomInIcon, CodeIcon, ImageIcon, ExternalLinkIcon
  },
  computed: {
    editSettingsPopup(): PopupSettings {
      return store.getters['htmlTemplateEditor/getEditSettingsPopup']
    },
    getEditedTemplate(): HtmlTemplate {
      return store.getters['htmlTemplateEditor/getEditedTemplate']
    },
  },
  methods: {
    async updateTemplate(rowIndex: number) {
      await store.dispatch('htmlTemplateEditor/updateTemplate', rowIndex)
      this.hidePopup()
    },
    hidePopup() {
      store.dispatch('htmlTemplateEditor/storeEditSettingsPopup', {active: false, rowIndex: null})
    },
    updateDataInRow(rowIndex: number): void {
      store.dispatch('htmlTemplateEditor/updateDataInRow', rowIndex)
    },
  }
}

<template>
  <div ref="container">
    <vs-row>
      <vs-col>
        <b-card :title="$t('htmlTemplate.title')">
          <create-new-template/>
          <br>
          <templates-table
              :columns="customerTemplates.columns"
          />
        </b-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import CreateNewTemplate from "@/views/components/mailing/htmlTemplate/CreateNewTemplate.vue";
import TemplatesTable from "@/views/components/mailing/htmlTemplate/TemplatesTable.vue";

export default {
  components: {
    CreateNewTemplate,
    TemplatesTable,
  },
  name: 'HtmlTemplate',
  data() {
    return {
      customerTemplates: {
        columns: [
          {
            displayName: 'Template name' ,
            name: 'templateName',
          },
          {
            displayName: 'Template Uniq Id',
            name: 'templateId',
          },

          {
            displayName: 'Image height',
            name: 'height',
          },
          {
            displayName: 'Image width',
            name: 'width',
          },
        ],
      },
    }
  },
}
</script>















































import Vue from 'vue'
import store from "@/store";
import {CodeIcon, EditIcon, ExternalLinkIcon, ImageIcon, ZoomInIcon} from "vue-feather-icons";
import {HtmlTemplate} from "@/store/htmlTemplate/htmlTemplate";
import {PopupSettings} from "@/store/htmlTemplate/htmlTemplateEditor";

export default Vue.extend({
  name: "EditTemplateCodePopup",
  components: {
    EditIcon, ZoomInIcon, CodeIcon, ImageIcon, ExternalLinkIcon
  },
  data() {
    return {
      interval: 0
    }
  },
  computed: {
    templatesCodePopup(): PopupSettings {
      return store.getters['htmlTemplateEditor/getTemplatesCodePopup']
    },
    getEditedTemplate(): HtmlTemplate {
      return store.getters['htmlTemplateEditor/getEditedTemplate']
    },
    getHeightWithPxPostfix() {
      return store.getters['htmlTemplateEditor/getHeightWithPxPostfix']
    },
    getWidthWithPxPostfix() {
      return store.getters['htmlTemplateEditor/getWidthWithPxPostfix']
    },
    getProductPreviewSnippet(): string {
      return store.getters['htmlTemplateEditor/getProductPreviewSnippet']
    },
  },
  methods: {
    async updateTemplate(rowIndex: number) {
      await store.dispatch('htmlTemplateEditor/updateTemplate', rowIndex)
      this.hidePopup()
    },
    hidePopup() {
      store.dispatch('htmlTemplateEditor/storeTemplatesCodePopup', {active: false, rowIndex: null})
    },
    updateDataInRow(rowIndex: number): void {
      store.dispatch('htmlTemplateEditor/updateDataInRow', rowIndex)
    },
    onUpdateCode(event: any): void {
      clearInterval(this.interval)
      store.dispatch('htmlTemplateEditor/setHtml', event.target.value)
      this.interval = setTimeout(async () => {
        await store.dispatch('htmlTemplateEditor/updateTemplate', this.templatesCodePopup.rowIndex)
      }, 3000)
    },
    storeEditedTemplate(rowIndex: number): void {
      store.dispatch('htmlTemplateEditor/setEditedTemplate', rowIndex)
    },
  }
})
